import Section from "@/components/ui/section";
import React from "react";
import KnowMore from "@/components/ui/know-more";
import Image from "next/image";
import Slick from "@/components/ui/carousel/slick";
export default function Reports() {
  const data = [
    {
      title: "Internal Migration in Asia",
      image: "/images/home/reports/Internal-migration-in-Asia-1.jpg",
      link: "/pdfs/internal-migration-in-asia.pdf",
    },
    {
      title: "Regional Consultation On Internal Migration In Asia",
      image:
        "/images/home/reports/Regional_Consultation_on_Internal_Migration_in_Asia-1.jpg",
      link: "/pdfs/regional-consultation-on-internal-migration-in-asia.pdf",
    },
    {
      title: "Ensuring Safe Migration in Asia  Series - Part 1",
      image: "/images/home/reports/Covid_24-july-1.jpg",
      link: "/pdfs/ensuring-safe-migration-in-asia-series-part-1.pdf",
    },
    {
      title: "Ensuring Safe Migration in Asia  Series - Part 2",
      image: "/images/home/reports/Climate-Change_24-july-1.jpg",
      link: "/pdfs/ensuring-safe-migration-in-asia-series-part-2.pdf",
    },
    {
      title: "Ensuring Safe Migration in Asia  Series - Part 3",
      image: "/images/home/reports/Labour-laws_24-july-1.jpg",
      link: "/pdfs/ensuring-safe-migration-in-asia-series-part-3.pdf",
    },
    {
      title: "Ensuring Safe Migration in Asia  Series - Part 4",
      image: "/images/home/reports/Social-Security_24-july-1.jpg",
      link: "/pdfs/ensuring-safe-migration-in-asia-series-part-4.pdf",
    },
  ];
  var responsive = [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 479,
      settings: {
        slidesToShow: 1,
      },
    },
  ];

  return (
    <Section>
      <h2 className="text-blue text-center mb-8">Reports:Trends & Insights</h2>
      <Slick responsive={responsive} autoplay={false}>
        {data?.map((row, key) => (
          <Block data={row} key={"report" + key} />
        ))}
      </Slick>
    </Section>
  );
}
const Block = ({ data }) => {
  const { title, image, link } = data;
  return (
    <div className="flex flex-col w-full h-auto gap-4 md:px-8 px-4">
      <div className="shrink-0 group flex justify-start items-start relative overflow-hidden lg:h-[30vw] lg:w-[90%] md:h-[45vw] h-[90vw] w-full">
        <Image
          src={image}
          alt={title}
          fill
          className="object-cover object-center w-full"
          sizes="(max-width: 479px) 479px,(max-width: 768px) 768px, (max-width: 1200px) 1200px,(max-width: 1920px) 1920px, 1000px"
        />
      </div>
      <div className="flex flex-col items-center justify-center w-full h-full gap-2">
        <p className="text-darkBlue mb-2 lg:text-[18px] text-base !text-center lg:min-h-[48px] min-h-[32px]">
          {title}
        </p>

        <KnowMore
          target="_blank"
          path={link}
          text={"Read More"}
          variant="darkBlue"
          className="mt-auto"
        />
      </div>
    </div>
  );
};
