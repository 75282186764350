import React from "react";
import Section from "@/components/ui/section";
import Image from "next/image";
// import WorldMap from "@/components/ui/world-map";
export default function WhereWeWork() {
  return (
    <Section className="w-full overflow-hidden">
      <h2 className="text-darkBlue text-center">Where we work</h2>
      <div className="flex flex-col items-center justify-start gap-10 mt-8">
        <div className="relative lg:h-[450px] w-full md:h-[400px] h-56 shrink-0">
          <Image
            src="/images/about/Global Map edited 2 png.webp"
            alt={"where we work"}
            fill
            sizes="(max-width: 479px) 479px,(max-width: 768px) 768px, (max-width: 1200px) 1200px,(max-width: 1920px) 1920px, 1000px"
            className="object-contain object-center w-full"
          />
        </div>
        {/* <WorldMap /> */}
        <p className="text-body font-normal">
          <strong>Asia:</strong> India, Nepal, and Bangladesh
          <br />
          <strong>Southeast Asia:</strong> Philippines, and Indonesia
        </p>
      </div>
    </Section>
  );
}
