import React from "react";
import Section from "@/components/ui/section";
import KnowMore from "@/components/ui/know-more";
import Image from "next/image";
import dynamic from "next/dynamic";
const ReactPlayer = dynamic(() => import("react-player/lazy"));
export default function TedTalk() {
  return (
    <Section className="bg-[#F5F9FC]">
      <div className="md:grid md:grid-cols-2 md:auto-rows-auto flex flex-col-reverse md:gap-12 gap-8 w-full">
        <div className="flex flex-col w-full gap-2 h-full justify-center">
          <h2 className="text-darkBlue">
            A Path to Social Safety for Migrant Workers
          </h2>
          <p>
            Watch Ashif Shaikh, the convener of MRC, share the idea behind our
            work and how we aim to work with governments, industry, and civil
            society to support those who build our world.
          </p>
          {/* <KnowMore
            variant="darkBlue"
            path="https://www.ted.com/talks/ashif_shaikh_a_path_to_social_safety_for_migrant_workers?hasSummary=true"
            text="Watch The Talk"
          /> */}
        </div>
        <div className="relative md:h-[450px] w-full h-[200px] shrink-0 rounded-2xl overflow-hidden">
          <iframe
            src="https://embed.ted.com/talks/ashif_shaikh_a_path_to_social_safety_for_migrant_workers?hasSummary=true&subtitle=en"
            width="1024px"
            height="576px"
            title="A path to social safety for migrant workers"
            className="absolute left-0 top-0 w-full h-full"
            frameborder="0"
            allowfullscreen
            onload="window.parent.postMessage('iframeLoaded', 'https://embed.ted.com')"
          ></iframe>
        </div>
      </div>
      <div id="reports" />
    </Section>
  );
}
