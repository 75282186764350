import React from "react";
import Seo from "@/utils/seo";
import Banner from "@/components/pages/home/banner";
import About from "@/components/pages/home/about";
import WhatWeDo from "@/components/pages/home/what-we-do";
// import SafeMigration from "@/components/pages/home/safe-migration";
import Reports from "@/components/pages/home/reports";
import Head from "next/head";
import WhereWeWork from "@/components/pages/home/where-we-work";
import TedTalk from "@/components/pages/home/ted-talk";
export default function Home() {
  const seo = {
    slug: "/",
    metaTitle:
      "People’s Courage International | Non-Profit Charity Organizations in USA",
    metaDescription:
      "People’s Courage International is a USA-based not-for-profit organization. Join our charity and help us provide critical support to those in need around the world.",
  };
  const jsonLd = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": ["NGO", "Organization"],
        "@id": "https://peoplescourageinternational.org/#organization",
        name: "People\u2019s Courage International",
        url: "https://peoplescourageinternational.org/",
        email: "admin",
        logo: {
          "@type": "ImageObject",
          "@id": "https://peoplescourageinternational.org/#logo",
          url: "https://globaljansahas.org/wp-content/uploads/2023/04/global-jan-sahas-logo.jpg",
          contentUrl:
            "https://globaljansahas.org/wp-content/uploads/2023/04/global-jan-sahas-logo.jpg",
          caption: "People\u2019s Courage International",
          inLanguage: "en-US",
          width: "112",
          height: "112",
        },
      },
      {
        "@type": "WebSite",
        "@id": "https://peoplescourageinternational.org/#website",
        url: "https://peoplescourageinternational.org",
        name: "People\u2019s Courage International",
        publisher: {
          "@id": "https://peoplescourageinternational.org/#organization",
        },
        inLanguage: "en-US",
        potentialAction: {
          "@type": "SearchAction",
          target:
            "https://peoplescourageinternational.org/?s={search_term_string}",
          "query-input": "required name=search_term_string",
        },
      },
      {
        "@type": "WebPage",
        "@id": "https://peoplescourageinternational.org/#webpage",
        url: "https://peoplescourageinternational.org/",
        name: "People\u2019s Courage International | Non-Profit Charity Organizations in USA",
        datePublished: "2023-03-21T06:26:18+00:00",
        dateModified: "2024-03-08T13:03:11+00:00",
        about: {
          "@id": "https://peoplescourageinternational.org/#organization",
        },
        isPartOf: { "@id": "https://peoplescourageinternational.org/#website" },
        inLanguage: "en-US",
      },
    ],
  };
  return (
    <>
      <Seo seo={seo} />
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(jsonLd),
          }}
        />
      </Head>
      <Banner />
      <About />
      <WhatWeDo />
      <WhereWeWork />
      <TedTalk />
      {/* <SafeMigration /> */}
      <Reports />
    </>
  );
}
